import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect } from 'react';
import MarkdownIt from 'markdown-it';
import Prism from 'prismjs';
import Img from 'gatsby-image';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const IframeContainer = styled.span`
  padding-bottom: 56.25%; 
  position: relative; 
  display: block; 
  width: 100%;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute; 
    top: 0; 
    left: 0;
  }`

const md = new MarkdownIt({
    html: true,
    linkify: false,
});

const codeSnippet = (markdown) => {
    useEffect(() => {
        Prism.highlightAll();
    });

    return (
        <div dangerouslySetInnerHTML={{ __html: md.render(markdown) }} />
    );
};

// https://github.com/contentful/rich-text/issues/61
const getContentfulEmbeddedData = (contentID, type = 'image') => {
    const { assets, codes } = useStaticQuery(
        graphql`
            query CONTENTFUL_IMAGE_QUERY {
                assets: allContentfulAsset {
                    edges {
                        node {
                            contentful_id
                            fluid(maxWidth: 800) {
                                ... GatsbyContentfulFluid
                            }
                        }
                    }
                },
                codes: allContentfulCode {
                    edges {
                        node {
                            contentful_id
                            code {
                                code
                            }
                        }
                    }
                }
            }
        `
    )

    let contents = null;
    if (type === 'image') {
        contents = assets;
    }
    else if (type === 'code') {
        contents = codes;
    }
    else {
        console.log('content type not valid');
    }

    const content = contents.edges.find(({ node }) => node.contentful_id === contentID)
    return content
};

export const richTextOptions = {
    renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
            const content = getContentfulEmbeddedData(node.data.target.sys.id, 'code');
            const markdown = content ? content.node.code.code : null;
            return codeSnippet(markdown);
        },
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
            const content = getContentfulEmbeddedData(node.data.target.sys.id, 'image');
            const img = content ? content.node.fluid : null;
            if (img) {
                return <Img fluid={img} />
            }
        },
        [INLINES.HYPERLINK]: (node) => {
            const title = node.content[0].value;
            const link = node.data.uri;
            if ((link).includes("youtube.com/embed")) {
                return <IframeContainer><iframe src={link} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></IframeContainer>
            }
            else {
                return <a href={link} target="_blank">{title}</a>;
            }
        }
    }
};

export const startsWYoutubeVideo = (content) => {
    const first_content = content.content ? content.content[0].content : [];
    for (const i in first_content) {
        const node = first_content[i];
        const node_type = node.nodeType;
        if (node_type === 'hyperlink') {
            const link = node.data.uri;
            if ((link).includes("youtube.com/embed")) {
                return true;
            }
        }
    }
    return false;
};

