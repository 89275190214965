import React from 'react'
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';


import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
    RedditShareButton,
    RedditIcon
} from 'react-share'

const StyledSocialShareIcons = styled.div`
  display:flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 7px;

  button {
    margin-right: 0.2rem;
  }

  & > button svg{
    &:hover{
       circle{
          fill: var(--primary-color) !important;
      }
  }
`;


const SocialShareIcons = ({ title, url, twitterHandle, tags, size = 30, round = true }) => {
  return (
    <StyledSocialShareIcons>
      <FacebookShareButton url={url} element='span'>
        <FacebookIcon size={size} round={round} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title} via={twitterHandle} hashtags={tags}>
        <TwitterIcon size={size} round={round} />
      </TwitterShareButton>

      <LinkedinShareButton url={url} >
        <LinkedinIcon size={size} round={round} />
      </LinkedinShareButton>

      {/* <RedditShareButton url={url} title={title} >
        <RedditIcon size={size} round={round} />
      </RedditShareButton> */}

      { isMobile &&
        (<WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={size} round={round} />
        </WhatsappShareButton>)
      }
    </StyledSocialShareIcons>
  )

}
export default SocialShareIcons