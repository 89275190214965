import styled from '@emotion/styled';
import Img from 'gatsby-image';
import React from 'react';
import Icon from './icon';


const StyledAuthor = styled.div`
    display: flex;
    align-items: center;

    img {
        border-radius: 50%;
    }
`;
const StyledInfo = styled.div`
    padding: 0 0.5rem;
    font-size: 0.8rem;
`;
const StyledAuthorImage = styled.div`
    display: flex;
`;
const StyledAuthorFullName = styled.div`
    font-size: 0.8rem;
`;
const StyledReadingTime = styled.div`
    svg {
        margin-right: 0.25rem;
    }
`;

export const AuthorSnippet = ({ author, readingTime }) => {
    const fullName = author.fullName;
    const profileImage = author.profileImage ? author.profileImage.fixed : null;
    return (
        <StyledAuthor>
            <StyledAuthorImage>
                <Img fixed={profileImage} alt={`Image de profil ${fullName}`} />
            </StyledAuthorImage>
            <StyledInfo>
                <StyledAuthorFullName>
                    {fullName}
                </StyledAuthorFullName>
                <StyledReadingTime>
                    <Icon icon='clock' prefix='fas' size='sm'/>
                    <span>{readingTime}</span>
                </StyledReadingTime>
            </StyledInfo>
        </StyledAuthor>
    );
};