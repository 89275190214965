import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import TagList from '../components/tag-list';
import SocialIcons from '../components/social-icons';
import SocialShareIcons from "../components/social-share-icons"
import FeaturedPost from '../components/featured-post';
import { AuthorSnippet } from '../components/author';
import { mq } from '../components/_shared/media';
import { blogMenuLinks } from '../components/_config/menu-links';
import { socialIconList } from '../components/_config/social-icon-list';
import { StyledH1 } from '../components/_shared/styled-headings';
import { StyledSection } from '../components/_shared/styled-section';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { richTextOptions, startsWYoutubeVideo } from "../components/contentful-rich-text-tools";
import { InlineSubscriptionForm } from "../components/email-subscription";


const StyledBlogSection = styled(StyledSection)`
  min-height: calc(100vh - var(--header-height));

  & > .gatsby-image-wrapper {
    width: 100%;
  }
`;

const StyledBlogTitle = styled(StyledH1)`
  margin-top: 3rem;
`;
const StyledBlogText = styled.div`
  padding: 2rem;
  width: 100%;
  background: var(--bg-code);
  border-radius: var(--radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;
const StyledAuthorInfo = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "separator separator"
    "author social";

  align-items: center;
  margin-bottom: 1.5rem;

  ${mq.gt.sm} {
    width: 80%;
  }
`;

const StyledAuthorSnippet = styled.div`
  grid-area: author;

  ${mq.gt.xs} {
    padding-left: 1rem;
  }
`;

const StyledSocialShare = styled.div`
    grid-area: social;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${mq.gt.xs} {
      padding-right: 1rem;
    }
`;

const SocialShareMessage = styled.div`
padding-right: 0.5rem;
font-size: 0.8rem;
display: none;

  ${mq.gt.xs} {
    display: flex;
  }
`;

const StyledSeparator = styled.div`
  grid-area: separator;
  width: 100%;
  height: 1px;
  margin: 1rem auto;
  background-color: var(--body-color);
`;

// Embed Youtube video in blog post
// https://www.contentfulcommunity.com/t/embed-youtube-or-vimeo-video-directly-into-rich-text-content-type/2639

const BlogPost = ({ data, location }) => {
  const post = data.contentfulArticle;
  // console.log(post);
  const coverImage = post.coverImage ? post.coverImage.fluid : null;
  const {
    tags = [], title,
    fields: { readingTime },
    author, description, 
    seo
  } = post;
  const content = JSON.parse(post.content.raw);
  const beginsWVideo = startsWYoutubeVideo(content);
  const formId = data.site.siteMetadata.convertKitFormId;
  const twitterHandle = data.site.siteMetadata.twitterUsername;
  
  //For the social share buttons
  // const siteUrl = data.site.siteMetadata.url;
  // const url = `${siteUrl}${location.pathname}`;
  const url = location.href;
  return (
    <Layout menuLinks={blogMenuLinks}>
      <SEO title={title}
        description={description.description}
        image={coverImage.src}
        pathname={location.pathname}
        keywords={seo ? seo.keywords : null }
      />
      <StyledBlogSection>
        <StyledBlogTitle>{title}</StyledBlogTitle>
        <TagList tags={tags} />

        <StyledAuthorInfo>
          <StyledSeparator />
          <StyledAuthorSnippet>
            <AuthorSnippet author={author} readingTime={readingTime.text} />
          </StyledAuthorSnippet>
          <StyledSocialShare>
            <SocialShareMessage>Partager:</SocialShareMessage>
            <SocialShareIcons url={url} title={title} tags={tags} twitterHandle={twitterHandle} />
          </StyledSocialShare>
        </StyledAuthorInfo>

        {!beginsWVideo && coverImage && <Img fluid={coverImage} />}

        <StyledBlogText>
          {documentToReactComponents(content, richTextOptions)}
          <SocialIcons icons={socialIconList} message='Suivez-moi sur les réseaux sociaux' />
          {/* <InlineSubscriptionForm form_id={formId} /> */}
          <FeaturedPost featured={data.allContentfulFormation.nodes} />
        </StyledBlogText>
      </StyledBlogSection>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    contentfulArticle(slug: { eq: $slug } ) {
      title
      tags
      updatedAt(formatString: "D. MMMM YYYY")
      description {
        description
      }
      coverImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
      }
      fields {
        readingTime {
          text
        }
      }
      content {
        raw
      }
      author {
        fullName
        profileImage {
          fixed(width: 50, height: 50, quality: 100) {
            ...GatsbyContentfulFixed
          }
        }
      }
    }

    allContentfulFormation(filter: {featured: {eq: true}}, limit: 4, sort: {order: DESC, fields: date}) {
      nodes {
        id
        title
        duration
        techs
        slug
        date
        price
        promoLink
        description {
          description
        }
        coverImage {
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid
            }
        }
      }
    }
    site {
      siteMetadata {
        url
        twitterUsername
        convertKitFormId
      }
    }

  }
`;
