import React, { useState } from "react"
import styled from '@emotion/styled';
import { StyledH2Simple } from './_shared/styled-headings';
import { StyledButton } from './links/button-link';
import { mq } from './_shared/media';
import Icon from './icon';

const StyleContainer = styled.div`
    margin: 2rem 0px;
    padding: 1.5rem;
    border: 1px solid var(--body-color);
    border-radius: var(--radius);
`;

const StyledForm = styled.form`
    display: flex;
    
    & > input {
        flex-grow: 3;
        margin-right: 0.2rem;
    }

    & > button {
        flex-grow: 1;
    }

    ${mq.lt.md} {
        flex-direction: column;

        & > input {
            margin-bottom: 0.4rem;
        }

        & > button {
            width:7rem;
            margin: 0px auto;
        }
    }
`;

const StyledInput = styled.input`
    padding: 0.4rem 0.8rem;
`;

const StyledMessageContainer = styled.div`
    text-align: center;
    display: flex;
    justify-content: center;
`;

const StyledMessage = styled.p`
    text-align: center;
    flex-basis: 80%;

    & > svg {
        margin-right: 0.5rem;
    }
`;

const StyledMessageSuccess = styled(StyledMessage)`
    color: #4eb174;
`;

const StyledMessageFailure = styled(StyledMessage)`
    color: #ff0000;
`;

const StyledTextDescription = styled.p`
    color: var(--title-color) !important;
`;

const StyledMessageNoShare = styled.p`
    color: gray;
`;

export const InlineSubscriptionForm = ({ form_id }) => {
    const [status, setStatus] = useState(null);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    //FORM_URL should be the same as the form action url
    const FORM_URL = `https://app.convertkit.com/forms/${form_id}/subscriptions`;

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        try {
            const response = await fetch(
                FORM_URL,
                {
                    method: 'post',
                    body: data,
                    headers: {
                        accept: 'application/json',
                    },
                }
            );
            setName('');
            setEmail('');
            const json = await response.json();
            if (json.status === 'success') {
                setStatus('SUCCESS');
                return;
            }
        } catch (err) {
            setStatus('ERROR');
            // console.log(err);
        }
    };

    const handleEmailChange = event => {
        const { value } = event.target;
        setEmail(value);
    }

    const handleNameChange = event => {
        const { value } = event.target;
        setName(value);
    }

    return (
        <StyleContainer>
            <StyledH2Simple>Téléchargez Votre Guide & Newsletter</StyledH2Simple>
            <StyledTextDescription>
                {/* If you've found any of my articles useful,
                subscribe to receive more quality articles
                straight to your inbox. */}
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio delectus voluptatum modi ab dicta nulla, non numquam animi molestias molestiae dolor totam facere aspernatur excepturi?
            </StyledTextDescription>
           
            <StyledMessageContainer>
                {/* Success */}
                {status === 'SUCCESS' && <StyledMessageSuccess>
                    <Icon icon='check-circle' prefix='fas' />
                Un email vous a été envoyé!
                <br /> Téléchargez votre guide en confirmant votre email.
                <br /> (Pensez à vérifier votre dossier 'spams').
                </StyledMessageSuccess>}
                {/* Erreur */}
                {status === 'ERROR' && <StyledMessageFailure>
                    <Icon icon='times-circle' prefix='fas' />
                Oops, une erreur est survenue! Réessayez.
            </StyledMessageFailure>}
            </StyledMessageContainer>

            <StyledForm action={FORM_URL}
                method="post"
                onSubmit={handleSubmit}>
                <StyledInput type="text" aria-label="Prénom"
                    //The name should be the same as on the form.
                    name="Prénom"
                    placeholder="Prénom"
                    onChange={handleNameChange}
                    value={name}
                    required />
                <StyledInput type="email" aria-label="Adresse Email"
                    //The name should be the same as on the form.
                    name="email_address"
                    placeholder="Adresse Email"
                    onChange={handleEmailChange}
                    value={email}
                    required />

                <StyledButton type="submit">
                    Télécharger
                </StyledButton>

            </StyledForm>
            {/* <StyledMessageNoShare>
                I won't send you spam and you can unsubscribe at any time
                Vos informations ne seront jamais partagées.
            </StyledMessageNoShare> */}
        </StyleContainer>
    );
};

export default InlineSubscriptionForm