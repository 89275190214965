import styled from '@emotion/styled';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import { mq } from './_shared/media';
import { css } from '@emotion/core';
import { ButtonLink } from './links/button-link';
import { StyledContentLink } from './_shared/styled-content-link';
import { StyledH3Simple, StyledH2 } from './_shared/styled-headings';
import { StyledImageContainer } from './_shared/styled-image-container';
import { formationsBaseUrl } from './_config/site-base-urls';
import { contentBox, flexCenter, flexEnd } from './_shared/styled-mixins';

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  padding: 1.5rem 0;
  max-width: 1000px;

  & h3 {
    margin-top: 0px;
  }
`;

const StyledFeaturedProject = styled.article`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1.5rem;
  padding: 1.5rem 0;
  align-items: center;

  ${mq.gt.sm} {
    grid-template-columns: repeat(2, 1fr);
  }
  &:nth-of-type(even) {
    direction: rtl;
  }
  &:nth-of-type(even) * {
    direction: ltr;
  }
`;

const overlayDefault = css`
  pointer-events: none;
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  color: var(--title-color);
  align-items: center;
  font-weight: 500;
  line-height: 1rem;
`;

const StyledDurationOverlay = styled.div`
  ${overlayDefault};
  top: 0.5rem;
  left: 0.5rem;
  height: 3rem;
  width: 3rem;
  background: var(--bg-code);
  border-radius: var(--radius);
`;

const StyledPriceOverlay = styled.div`
  ${overlayDefault};
  top: 0.5rem;
  right: 0rem;
  padding: 0.2rem 0.4rem;
  background: var(--secondary-color);
  font-size: 0.75rem;
`;

const StyledCtaOverlay = styled.div`
  a {
    border-radius: 0 !important;
  }
`;

const StyledProjectInfoContainer = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const StyledDescription = styled.section`
  ${contentBox}
  max-height: 180px;
  position: relative;
  padding: 10px;
  background-color: #252839 !important;

  > p {
    height: 100%;
    margin: 0;
    font-size: 0.8rem;
    overflow: hidden;
  }
`;


const FeaturedPost = ({ featured }) => {
  const featuredHome = featured.map(formation => {
    const coverImage = formation.coverImage ? formation.coverImage.fluid : null;

    const { title, duration, price, promoLink } = formation;
    const description = formation.description.description;
    const link = promoLink;

    return (
      <StyledFeaturedProject key={formation.id}>
        {coverImage && (
          <StyledImageContainer hasHover>
            <Img fluid={coverImage} />
            <StyledDurationOverlay>
              <span>{duration}</span>
            </StyledDurationOverlay>
            <StyledPriceOverlay>
              <span>{price}</span>
            </StyledPriceOverlay>
            <StyledCtaOverlay>
              <ButtonLink label="Apprendre Maintenant" link={link} aria-label={`Formation ${title}`} />
            </StyledCtaOverlay>
          </StyledImageContainer>
        )}
        <StyledProjectInfoContainer>
          <StyledH3Simple>{title}</StyledH3Simple>
          <StyledDescription>
            <p>{description}</p>
          </StyledDescription>
        </StyledProjectInfoContainer>
      </StyledFeaturedProject>
    );
  });

  return (
    <StyledSection>
      <StyledH2>Allez plus loin</StyledH2>
      <span>Supportez mon travaille en vous inscrivant dans mes formations complètes.</span>
      {featuredHome}
    </StyledSection>
  );
};

FeaturedPost.propTypes = {
  featured: PropTypes.array.isRequired,
};

export default FeaturedPost;
